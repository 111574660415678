<template>
  <div class="console-search">
    <h5>彩票查询</h5>
    <div class="main mt-3">
      <div class="filter">
        <form action="" class="form-inline" @submit="onSubmit">
          <div class="form-row align-items-center">
            <div class="col-auto">
              <input type="text" class="form-control" placeholder="请输入订单号或物流码">
            </div>
            <div class="col-auto">
              <button type="button" class="btn btn-primary">搜索</button>
            </div>
          </div>
        </form>
      </div>

      <div class="mt-3">
        <div class="list-group w-50">
          <a href="#" class="list-group-item list-group-item-action active">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">兑奖成功</h5>
              <small>2022-03-17 12:00:00</small>
            </div>
            <p class="mb-1">订单号码：123008ifaf98fad2fa3423423</p>
            <small>182****5678</small>
          </a>
          <a href="#" class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">兑奖失败</h5>
              <small>2022-03-17 12:00:00</small>
            </div>
            <p class="mb-1">订单号码：123008ifaf98fad2fa3423423</p>
            <small>182****5678</small>
          </a>
          <a href="#" class="list-group-item list-group-item-action ">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">兑奖</h5>
              <small>2022-03-17 12:00:00</small>
            </div>
            <p class="mb-1">订单号码：123008ifaf98fad2fa3423423</p>
            <small>182****5678</small>
          </a>
          <a href="#" class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">补票</h5>
              <small>2022-03-17 12:00:00</small>
            </div>
            <p class="mb-1">订单号码：123008ifaf98fad2fa3423423</p>
            <small>182****5678</small>
          </a>
          <a href="#" class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">刮票失败</h5>
              <small>2022-03-17 12:00:00</small>
            </div>
            <p class="mb-1">订单号码：123008ifaf98fad2fa3423423</p>
            <small>182****5678</small>
          </a>
          <a href="#" class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">刮票</h5>
              <small>2022-03-17 12:00:00</small>
            </div>
            <p class="mb-1">订单号码：123008ifaf98fad2fa3423423</p>
            <small>182****5678</small>
          </a>
          <a href="#" class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">兑换彩票</h5>
              <small>2022-03-17 12:00:00</small>
            </div>
            <p class="mb-1">订单号码：123008ifaf98fad2fa3423423</p>
            <small>182****5678</small>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Lottery from "@/model/Lottery"
export default {
  data() {
    return {
      list: [],
      id: ''
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      Lottery.history(this.id).then(response => {
        if(response.data.code === 0) {
          this.list = response.data.data;
        }
      })
    }
  }
}
</script>